import React, { memo } from 'react'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'
import Logo from '../../../../components/UI/Logos/Logo'
import AccountMenu from '../../../../components/AccountMenu/AccountMenu'
import { LocalStorage } from 'ts-localstorage'
import { ADMIN_USER_ID } from '../../../../config/consts'
import { IUser } from '../../../../types/profile.type'
import ActionButtonsArea from '../ActionButtonsArea/ActionButtonsArea'
import useWindowSize from '../../../../hooks/useWindowsSize'
import AdditionalNotificationArea from '../AdditionalNotificationArea/AdditionalNotificationArea'

const { Header: LayoutHeader } = Layout

interface IProps {
  user: IUser
  toggleMobileMenu: () => void
}

const Header = ({ user, toggleMobileMenu }: IProps) => {
  const adminUserId = LocalStorage.getItem(ADMIN_USER_ID)

  const { isDesktop, isMobile } = useWindowSize()

  return (
    <LayoutHeader>
      {isMobile && <AdditionalNotificationArea user={user} adminUserId={adminUserId} sx={{ paddingInline: 20 }} alignEmail={'center'} />}
      <div className="header">
        {
          isMobile && (
            <>
              <ActionButtonsArea isMenuOpen={false} onToggleMenu={null} toggleMobileMenu={toggleMobileMenu} />
              <Link to="/" className="logo" style={{ marginRight: '33px' }}>
                <Logo name="ioniBlack" width={'148'} height={'28'} />
              </Link>
            </>
          )
        }
        <div className={isDesktop ? 'right-header-item' : 'center-item'}>
          <AccountMenu />
        </div>

        {isDesktop && <AdditionalNotificationArea user={user} adminUserId={adminUserId} />}
      </div>
    </LayoutHeader>
  )
}

export default memo(Header)
